import _inherits from "inherits";
import _events from "events";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var inherits = _inherits;
var EventEmitter = _events.EventEmitter;
exports = Queue;
exports.default = Queue;

function Queue(options) {
  if (!((this || _global) instanceof Queue)) {
    return new Queue(options);
  }

  EventEmitter.call(this || _global);
  options = options || {};
  (this || _global).concurrency = options.concurrency || Infinity;
  (this || _global).timeout = options.timeout || 0;
  (this || _global).autostart = options.autostart || false;
  (this || _global).results = options.results || null;
  (this || _global).pending = 0;
  (this || _global).session = 0;
  (this || _global).running = false;
  (this || _global).jobs = [];
  (this || _global).timers = {};
}

inherits(Queue, EventEmitter);
var arrayMethods = ["pop", "shift", "indexOf", "lastIndexOf"];
arrayMethods.forEach(function (method) {
  Queue.prototype[method] = function () {
    return Array.prototype[method].apply((this || _global).jobs, arguments);
  };
});

Queue.prototype.slice = function (begin, end) {
  (this || _global).jobs = (this || _global).jobs.slice(begin, end);
  return this || _global;
};

Queue.prototype.reverse = function () {
  (this || _global).jobs.reverse();

  return this || _global;
};

var arrayAddMethods = ["push", "unshift", "splice"];
arrayAddMethods.forEach(function (method) {
  Queue.prototype[method] = function () {
    var methodResult = Array.prototype[method].apply((this || _global).jobs, arguments);

    if ((this || _global).autostart) {
      this.start();
    }

    return methodResult;
  };
});
Object.defineProperty(Queue.prototype, "length", {
  get: function () {
    return (this || _global).pending + (this || _global).jobs.length;
  }
});

Queue.prototype.start = function (cb) {
  if (cb) {
    callOnErrorOrEnd.call(this || _global, cb);
  }

  (this || _global).running = true;

  if ((this || _global).pending >= (this || _global).concurrency) {
    return;
  }

  if ((this || _global).jobs.length === 0) {
    if ((this || _global).pending === 0) {
      done.call(this || _global);
    }

    return;
  }

  var self = this || _global;

  var job = (this || _global).jobs.shift();

  var once = true;
  var session = (this || _global).session;
  var timeoutId = null;
  var didTimeout = false;
  var resultIndex = null;
  var timeout = job.hasOwnProperty("timeout") ? job.timeout : (this || _global).timeout;

  function next(err, result) {
    if (once && self.session === session) {
      once = false;
      self.pending--;

      if (timeoutId !== null) {
        delete self.timers[timeoutId];
        clearTimeout(timeoutId);
      }

      if (err) {
        self.emit("error", err, job);
      } else if (didTimeout === false) {
        if (resultIndex !== null) {
          self.results[resultIndex] = Array.prototype.slice.call(arguments, 1);
        }

        self.emit("success", result, job);
      }

      if (self.session === session) {
        if (self.pending === 0 && self.jobs.length === 0) {
          done.call(self);
        } else if (self.running) {
          self.start();
        }
      }
    }
  }

  if (timeout) {
    timeoutId = setTimeout(function () {
      didTimeout = true;

      if (self.listeners("timeout").length > 0) {
        self.emit("timeout", next, job);
      } else {
        next();
      }
    }, timeout);
    (this || _global).timers[timeoutId] = timeoutId;
  }

  if ((this || _global).results) {
    resultIndex = (this || _global).results.length;
    (this || _global).results[resultIndex] = null;
  }

  (this || _global).pending++;
  self.emit("start", job);
  var promise = job(next);

  if (promise && promise.then && typeof promise.then === "function") {
    promise.then(function (result) {
      return next(null, result);
    }).catch(function (err) {
      return next(err || true);
    });
  }

  if ((this || _global).running && (this || _global).jobs.length > 0) {
    this.start();
  }
};

Queue.prototype.stop = function () {
  (this || _global).running = false;
};

Queue.prototype.end = function (err) {
  clearTimers.call(this || _global);
  (this || _global).jobs.length = 0;
  (this || _global).pending = 0;
  done.call(this || _global, err);
};

function clearTimers() {
  for (var key in (this || _global).timers) {
    var timeoutId = (this || _global).timers[key];
    delete (this || _global).timers[key];
    clearTimeout(timeoutId);
  }
}

function callOnErrorOrEnd(cb) {
  var self = this || _global;
  this.on("error", onerror);
  this.on("end", onend);

  function onerror(err) {
    self.end(err);
  }

  function onend(err) {
    self.removeListener("error", onerror);
    self.removeListener("end", onend);
    cb(err, (this || _global).results);
  }
}

function done(err) {
  (this || _global).session++;
  (this || _global).running = false;
  this.emit("end", err);
}

export default exports;